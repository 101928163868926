.forgot-pwd-dark {
  float: right;
  text-align: left;
  color: #4487ee;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: -20px 40px 0px 0px;
  transition: 0.3s ease;
  font-weight: 500;
}

.forgot-pwd-sages {
  float: right;
  text-align: left;
  color: #f1a04d;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: -20px 40px 0px 0px;
  transition: 0.3s ease;
  font-weight: 500;
}

.forgot-pwd-dark:hover, .forgot-pwd-sages:hover {
  text-decoration: underline;
}

.email-pill-container-dark,
.email-pill-container-sages {
  cursor: default;
  border: 1px solid #ffffff99;
  padding: 10px 12px;
  font-size: 15px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  color: white;
  gap: 8px;
}

.cross-wrapper-dark,
.cross-wrapper-sages {
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: 0.3s ease;
  color: rgba(255, 255, 255, 0.8);
}

.cross-wrapper-dark:hover,
.cross-wrapper-sages:hover {
  color: rgba(255, 255, 255, 1);
}
