.resent-msg {
  height: 0px;
  width: 100%;
  color: #121926;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  animation: slow-load 0.5s;
}

.trust-checkbox {
  appearance: none;
  height: 16px;
  width: 16px;
  border: 1px solid #CDD5DF;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.trust-checkbox:checked {
  background-color: white;
  border-color: #276EDC;
}

.trust-checkbox:checked::after {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: url('/public/icons/check.svg');
}

.trust-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 9px;
}
