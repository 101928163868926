.issue-modal-content-dark {
  text-align: center;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  background: #121926;
  backdrop-filter: blur(40px);
}

.issue-modal-content-sages {
  text-align: center;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  background: black;
  backdrop-filter: blur(40px);
}

.issue-modal-content-dark.visible,
.issue-modal-content-sages.visible {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.issue-modal-content-dark .view-title,
.issue-modal-content-sages .view-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.issue-modal-content-dark form,
.issue-modal-content-sages form {
  padding-bottom: 20px;
}

.issue-modal-title-dark, 
.issue-modal-title-sages {
  position: relative;
  color: grey;
  text-align: left;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}

.issue-modal-title-dark input,
.issue-modal-title-sages input {
  margin: 15px 0 5px 0;
  width: 95%;
  color: white;
}

.issue-modal-title-dark input:hover,
.issue-modal-title-sages input:hover {
  border-color: white;
}

.issue-modal-title-dark input:focus-within,
.issue-modal-title-sages input:focus-within {
  border-color: white;
}

.issue-modal-message-dark,
.issue-modal-message-sages {
  font-family: "Inter", "Noto Sans JP", Arial, Helvetica, sans-serif;
  font-weight: 400;
  resize: none;
  display: block;
  height: 200px;
  width: 100%;
  outline-color: #00506e;
  font-size: 16px;
  box-sizing: border-box;
  margin: 15px 0;
  background-color: transparent;
  color: white;
  max-height: 80px;
  width: 95%;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  transition: 0.2s ease;
}

.issue-modal-message-dark:focus,
.issue-modal-message-sages:focus {
  border-color: white;
}

.issue-modal-message-dark:hover,
.issue-modal-message-sages:hover {
  border-color: white;
}

.issue-modal-message-dark::placeholder,
.issue-modal-message-sages::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.issue-modal-close-dark:hover,
.issue-modal-close-dark:focus,
.issue-modal-close-sages:hover,
.issue-modal-close-sages:focus {
  color: #00506e;
  text-decoration: none;
  cursor: pointer;
}