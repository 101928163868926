.forgot-pwd {
  float: right;
  text-align: left;
  color: #276EDC;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: -20px 40px 0px 0px;
  transition: 0.3s ease;
  font-weight: 500;
}

.forgot-pwd:hover {
  text-decoration: underline;
}

.email-pill-wrapper {
  display: inline-block;
  margin-bottom: 5px;
}

.email-pill-email {
  font-size: 16px;
  font-weight: 500;
}

.email-pill-container {
  cursor: default;
  border: 1px solid #CDD5DF;
  padding: 10px 12px;
  font-size: 15px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  color: #121926;
  gap: 8px;
}

.cross-wrapper {
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: 0.3s ease;
  color: #121926;
}

.cross-wrapper:hover {
  color:#23272f
}
