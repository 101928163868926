.qr-code-container {
    height: auto;
    display: flex;
    justify-content: space-around;
}

.mfa-footer {
    font-size: 14px;
    margin-top: 40px;
    padding: 0px 30px 0px 30px;
}

.qr-wrapper {
    height: 25px;
}

.qr-show {
    color: #276EDC;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}
