.PhoneInput.-dark .PhoneInputInput:focus,
.PhoneInput.-sages .PhoneInputInput:focus {
    border-color: white
}

.PhoneInput.-dark .PhoneInputInput:hover,
.PhoneInput.-sages .PhoneInputInput:hover {
    border-color: white
}

.PhoneInput.-dark .PhoneInputInput,
.PhoneInput.-sages .PhoneInputInput {
    border-color: rgba(255, 255, 255, 0.6);
    color: white;
}

.country-code-picker-dark,
.country-code-picker-sages {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    width: 110px;
    height: 44px;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-family: "Inter";
    font-weight: 400;
}

.country-code-picker-dark:hover,
.country-code-picker-sages:hover {
    border-color: white;
}
