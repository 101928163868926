.password-validation-dark,
.password-validation-sages {
  font-size: 12px;
  margin: -15px 0 0 40px;
  text-align: left;
  transition: 0.5s ease;
  color: white;
}

.password-validation-dark.true,
.password-validation-sages.true {
  color: rgb(0, 187, 0);
}

.password-validation-dark.null,
.password-validation-sages.null {
  color: #f04438;
}
