.password-container-wrapper {
    margin-bottom: 8px;
}

.input-cue {
    font-size: 16px;
    text-align: left;
    margin: 3px 0;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.password-validation {
    font-size: 12px;
    margin: -15px 0 0 40px;
    text-align: left;
    transition: 0.5s ease;
}

.password-validation.true {
    color: #F04438;
}

.password-validation.false {
    height: 14px;
}