.qr-modal-content-dark,
.qr-modal-content-sages {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.45);
    width: 510px;
    padding: 40px;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    backdrop-filter: blur(40px);
    height: 700px;
    box-sizing: border-box;
}

.qr-modal-content-dark.visible,
.qr-modal-content-sages.visible {
    opacity: 1;
    visibility: visible;
    z-index: 100;
}

.qr-modal-close-dark,
.qr-modal-close-sages {
    text-align: right;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    transition: 0.3s ease;
}

.qr-modal-close-dark:hover,
.qr-modal-close-dark:focus,
.qr-modal-close-sages:hover,
.qr-modal-close-sages:focus {
    color: #bcbcbc;
    text-decoration: none;
    cursor: pointer;
}

.qr-code-wrapper-dark,
.qr-code-wrapper-sages {
    border-radius: 2px;
    background-color: white;
    height: 212px;
    width: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-text-dark,
.qr-text-sages {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
    line-height: 24px;
}
