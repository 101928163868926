.phone-pill-container-dark,
.phone-pill-container-sages {
  cursor: default;
  border: 1px solid lightgray;
  padding: 3px 10px;
  font-size: 15px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  color: white;
}

.resent-msg-alt-dark, .resent-msg-alt-sages {
  width: 100%;
  color: white;
  margin-top: -16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  animation: slow-load 0.5s;
}