.email:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.email:hover {
  border-color: #4B5565;
}

.email:focus-within {
  border-color: #276EDC;
}

.email {
  margin-top: 50px;
}

.email::placeholder {
  color: #697586;
}