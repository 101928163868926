.status-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 16px;
  padding-bottom: 15px;
}

.error {
  color: rgb(217, 33, 33);
}

.success {
  color: rgb(71, 224, 71);
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-right: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 420px;
  background-color: #121926F5;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  top: 25px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
