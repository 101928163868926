.mfa-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 19px;
}

.mfa-input {
    height: 68px;
    width: 56px;
    font-size: 16px;
    text-align: center;
    color: #121926;
    border-radius: 4px;
    transition: 0.3s ease;
    border: 1px solid #CDD5DF;
    padding: 0;
}

.mfa-input.mfa-error {
  border: 1px solid #F04438;
}

.mfa-input:enabled:hover {
    cursor: pointer;
    border: 1px solid #4B5565;
    background: rgba(255, 255, 255, 0.08);
}

.mfa-input:focus {
    outline: none;
    border: 1px solid #276EDC;
}

/* HIDE UP/DOWN ARROWS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}