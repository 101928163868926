.success-header-dark,
.success-header-sages {
    color: white;
    font-weight: 500;
    margin-top: 0;
    padding: 0px 40px 12px 40px;
    font-size: 32px;
}

.success-title-dark,
.success-header-sages {
    color: rgb(255, 255, 255, 0.8);
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0px 40px 20px 40px;
    font-weight: 300;
}
