.password-container {
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #CDD5DF;
  margin: 0px 40px 30px 40px;
}

.password-container.inputError {
  border-bottom: 1px solid #F04438;
}

.password-container:hover {
  border-color: #4B5565;
}

.password-container:focus-within {
  border-color: #276EDC;
}

.password {
  border: 0;
  margin: 0;
  padding: 0 0 0 0;
  transition: none;
}

.password:focus {
  outline: none;
}

.password:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.password[type='password'] {
  font-family: 'Verdana';
  letter-spacing: 0.125em;
}

.password::placeholder {
  font-family: "Inter", "Noto Sans JP",Arial,Helvetica,sans-serif!important;
  letter-spacing: normal!important;
}

.eye-wrapper {
  height: 100%;
  font-size: 23px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
  color: #697586;
}
