.mfa-input-dark,
.mfa-input-sages {
    height: 68px;
    width: 56px;
    font-size: 16px;
    text-align: center;
    color: white;
    caret-color: transparent;
    border-radius: 4px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    transition: 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 0;
}

.mfa-input-dark.mfa-error,
.mfa-input-sages.mfa-error {
    border: 1px solid #F04438;
}

.mfa-input-dark:enabled:hover {
    cursor: pointer;
    border: 1px solid #4487EE;
    background: rgba(255, 255, 255, 0.08);
}

.mfa-input-sages:enabled:hover {
    cursor: pointer;
    border: 1px solid #F1A04D;
    background: rgba(255, 255, 255, 0.08);
}

.mfa-input-dark:focus {
    outline: none;
    border: 1px solid #4487EE;
}

.mfa-input-sages:focus {
    outline: none;
    border: 1px solid #F1A04D;
}