.phone-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.send-sms {
    width: 30%;
    height: 25px;
    margin-right: 30px;
    cursor: pointer;

    background-color: #ffffff;
    color: #00506e;
    border: 1px solid #00506e;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
}

.send-sms:enabled:hover {
    box-shadow: 0 0 5px #004e72;
}

.send-sms.enabled {
    font-size: 15px;
}

.phone-pill-wrapper {
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 5px;
}

.phone-pill-phone {
    margin-bottom: 2px;  /* better alignment */
}

.phone-pill-container {
    cursor: default;
    border: 1px solid lightgray;
    padding: 3px 10px;
    font-size: 15px;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
    color: #121926;
}

.resent-msg-alt {
    width: 100%;
    color: black;
    margin-top: -16px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    animation: slow-load 0.5s;
}