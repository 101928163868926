.PhoneInputInput:focus {
    border-color: #276EDC
}

.PhoneInputInput:hover {
    border-color: #276EDC
}

.PhoneInputInput {
    border-color: #CDD5DF;
}

.country-code-picker {
    border-bottom: 1px solid #CDD5DF;
    width: 110px;
    height: 44px;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 400;
}

.country-code-picker:hover {
    border-color: #276EDC;
}

.country-dropdown {
    position: absolute;
    top: 88px;
    left: 0px;
    z-index: 2;
    box-shadow: 0px 0px 8px 0px rgba(39, 110, 220, 0.24);
    max-height: 260px;
    width: 400px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
}

.country-dropdown::-webkit-scrollbar {
    background: white;
    width: 8px;
}

.country-dropdown::-webkit-scrollbar-thumb {
    background-color: #9AA4B2;
    border-radius: 2px;
}

.country-option {
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    height: 44px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-family: "Inter", "Noto Sans JP",Arial,Helvetica,sans-serif;;
    font-weight: 400;
}

.country-option:hover {
    background-color: #F6F9FE;
}

.phone-input {
    margin-bottom: 35px;
}

.phone-input::placeholder {
    color: #9AA4B2;
}

.phone-input:focus-within {
    border-color: #276EDC;
}
