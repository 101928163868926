.email.-dark:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    transition: all 0s 5000000s;
}

.email.-sages:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    transition: all 0s 5000000s;
}

.email.-dark:hover {
    border-color: white;
}

.email.-sages:hover {
    border-color: white;
}

.email.-dark:focus-within {
    border-color: white;
}

.email.-sages:focus-within {
    border-color: white;
}

.email::placeholder {
    color: #697586;
}