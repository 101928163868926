@keyframes slow-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  position: absolute;
  top: 0;
  left: 0;
}

input {
  font-family: "Inter", "Noto Sans JP",Arial,Helvetica,sans-serif;;
  font-weight: 400;
  width: calc(100% - 80px);
  padding: 15px 15px 15px 0px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 30px 0;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CDD5DF;
  color: #121926;
}

input:-webkit-autofill {
  transition: all 0s 500000s;
}

input::placeholder {
  color: #697586;
}

textarea:focus, input:focus{
  outline: none;
}

input.inputError {
  border-bottom: 1px solid #F04438;
}

p {
  color: #121926;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  margin: 0;
}

button {
  font-family: "Inter", "Noto Sans JP",Arial,Helvetica,sans-serif;;
  font-weight: 400;
  margin: 15px 0 12px 0;
  cursor: pointer;
  padding: 14px;
  font-size: 16px;
  border-radius: 4px;
  transition: 0.3s ease;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

button.proceed {
  width: calc(100% - 80px);
  background-color: transparent;
  color: #276EDC;
  border: 1px solid #276EDC;
  border-radius: 99px;
}

button.proceed:hover {
  background-color: #F6F9FE;
}

button:disabled {
  border-color: #E3E8EF;
  color: #CDD5DF;
}

button:disabled:hover {
  background-color: transparent;
}

button.cancel span {
  font-weight: normal;
}

button.cancel {
  width: calc(100% - 80px);
  background-color: transparent;
  color: #697586;
  border: #CDD5DF 1px solid;
  border-radius: 99px;
  margin: 0px 0px 10px 0px;
}

button.cancel:hover {
  background-color: #F8F9FB;
}
