@keyframes slide-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
    transform: translateY(-80%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slow-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.body-container-dark {
  background: #121926;
  border-radius: 0px 0px 8px 8px;
}

.body-container-sages {
  background: #000000;
  border-radius: 0px 0px 8px 8px;
}

.logo-container-dark {
  background: #121926;
  padding: 46px 40px;
  border-radius: 8px 8px 0px 0px;
}

.logo-container-sages {
  background: #000000;
  padding: 46px 40px;
  border-radius: 8px 8px 0px 0px;
}

.view-title-dark,
.view-title-sages {
  color: rgb(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0px 40px 20px 40px;
  font-weight: 400;
}

.view-header-dark,
.view-header-sages {
  color: white;
  font-weight: 600;
  margin: 0;
  padding: 40px 40px 20px 40px;
  font-size: 32px;
}

.issues-wrapper {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.having-trouble-dark,
.having-trouble-sages {
  font-size: 14px;
  color: #ffffff99;
}

.issues-dark {
  color: #4487ee;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s ease;
  font-weight: 600;
}

.issues-sages {
  color: #f1a04d;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s ease;
  font-weight: 600;
}

.issues-dark:hover, .issues-sages:hover {
  text-decoration: underline;
}

.divider-container-dark {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #121926;
}

.divider-container-sages {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
}

.divider-line-dark {
  width: 430px;
  border-bottom: #ffffff33 1px solid;
  margin: 0;
  background-color: #121926;
}

.divider-line-sages {
  width: 430px;
  border-bottom: #ffffff33 1px solid;
  margin: 0;
  background-color: #000000;
}
