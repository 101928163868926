.resent-msg-dark,
.resent-msg-sages {
    height: 0px;
    width: 100%;
    color: white;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    animation: slow-load 0.5s;
}

.trust-checkbox-dark,
.trust-checkbox-sages {
    appearance: none;
    height: 16px;
    width: 16px;
    border: 1px solid #FFFFFF66;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}

.trust-checkbox-dark:checked {
    background-color: #364152;
    border-color: white;
}

.trust-checkbox-sages:checked {
    background-color: #000000;
    border-color: white;
}

.trust-checkbox-dark:checked::after,
.trust-checkbox-sages:checked::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: url('/public/icons/check-white.svg');
}

.trust-container-dark,
.trust-container-sages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 9px;
    color: white;
}