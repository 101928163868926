.icon-wrapper {
    color: #32D583;
    font-size: 85px;
    transition: 0.3s ease;
    padding-top: 100px;
}

.success-header {
    color: #121926;
    font-weight: 500;
    margin-top: -10px;
    padding: 0px 40px 12px 40px;
    font-size: 32px;
}

.success-title {
    color: #697586;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0px 40px 20px 40px;
    font-weight: 300;
}
