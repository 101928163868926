.password-container-dark, .password-container-sages {
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin: 0px 40px 30px 40px;
}

.password-container-dark.inputError,
.password-container-sages.inputError {
  border-bottom: 1px solid #f04438;
}

.password-container-dark:hover,
.password-container-sages:hover {
  border-color: white;
}

.password-container-dark:focus-within,
.password-container-sages:focus-within {
  border-color: white;
}

.password-dark,
.password-sages {
  border: 0;
  margin: 0;
  padding: 0 0 0 0;
  transition: none;
  color: white;
}

.password-dark:focus,
.password-sages:focus {
  outline: none;
}

.password-dark:-webkit-autofill,
.password-sages:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.password-dark[type="password"],
.password-sages[type="password"] {
  font-family: "Verdana";
  letter-spacing: 0.125em;
}

.password-dark::placeholder,
.password-sages::placeholder {
  font-family: "Inter", "Noto Sans JP", Arial, Helvetica, sans-serif !important;
  letter-spacing: normal !important;
}

.eye-wrapper-dark, .eye-wrapper-sages {
  height: 100%;
  font-size: 23px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
  color: rgba(255, 255, 255, 0.6);
}
