.modal-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes slide-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
    transform: translateY(-80%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slow-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-modal {
  animation-delay: 2s;
  animation: slide-in 1.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: inline;
  text-align: center;
  width: 510px;
  z-index: 1; /* to overlay on background */
  border-radius: 5px;
  border: 1px solid #DDE8FA;
}

.main-modal-dark, .main-modal-sages {
  animation-delay: 2s;
  animation: slide-in 1.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: inline;
  text-align: center;
  width: 510px;
  z-index: 1; /* to overlay on background */
  border-radius: 5px;
}

.body-container {
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
}

.logo {
  /* height: 100px;  less glitchy on load */
  width: 310px;
}

.logo-container {
  background: #FFFFFF;
  padding: 46px 40px;
  border-radius: 8px 8px 0px 0px;
}

.break {
  margin: 15px 0;
}

.view-title {
  color: #697586;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0px 40px 20px 40px;
  font-weight: 400;
}

.view-header {
  color: #121926;
  font-weight: 600;
  margin: 0;
  padding: 40px 40px 20px 40px;
  font-size: 32px;
}

.issues-wrapper {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.having-trouble {
  font-size: 14px;
  color: #697586;
}

.issues {
  color: #276EDC;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s ease;
  font-weight: 600;
}

.issues:hover {
  text-decoration: underline;
}

/*Used when showing two buttons beside each other*/
.splitter {
  display: flex;
  justify-content: space-between;
}

.splitter .proceed {
  width: 44%;
}

.vertical-splitter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-image-container {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.bg-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.divider-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
}

.divider-line {
  width: 430px;
  border-bottom: #DDE8FA 1px solid;
  margin: 0;
  background-color: #FFFFFF;
}