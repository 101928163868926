
.qr-show-dark,
.qr-show-sages {
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}

.qr-show-dark:hover,
.qr-show-sages:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
