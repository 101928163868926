@keyframes slow-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.audienceMsgWrapper{
  background-color: #121926;
  padding: 20px;
  text-align: left;
  border-radius: 2px;
  display: flex;
  gap: 8px;
  vertical-align: top;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 6px;
  animation-name: slow-load;
  animation-duration: 3s;
  animation-delay: 2s; /* Need this delay */
  animation-fill-mode: forwards;
  opacity: 0;
  max-width: 500px;
}

.audienceMsgWrapper img {
  vertical-align: top;
  height: 20px;
}

.audienceMsgText{
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 1);
}

.audienceMsgSubtext{
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFFCC;
  margin-top: 10px;
}

.audienceMsgClose {
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  height: fit-content;
  margin-top: -5px;
}

.audienceMsgClose:hover {
  color: rgb(205, 205, 205);
}