input.-dark, input.-sages {
  font-family: "Inter", "Noto Sans JP", Arial, Helvetica, sans-serif;
  font-weight: 300;
  width: calc(100% - 80px);
  padding: 15px 15px 15px 0px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 30px 0;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  color: white;
}

input::placeholder.-dark, input::placeholder.-sages {
  color: rgba(255, 255, 255, 0.6);
}

input.inputError.-dark, input.inputError.-sages {
  border-bottom: 1px solid #f04438;
}

p.-sages, p.-dark {
  color: #ffffff;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  margin: 0;
}

button.proceed-dark {
  width: calc(100% - 80px);
  background-color: #4487ee;
  color: #ffffff;
  border: none;
  border-radius: 99px;
}

button.proceed-sages {
  width: calc(100% - 80px);
  background-color: #F1A04D;
  color: #ffffff;
  border: none;
  border-radius: 99px;
}

button.proceed-dark:enabled:hover {
  background: #5393f3;
}

button.proceed-sages:enabled:hover {
  background: #fdae5e;
}

button.cancel-dark span, button.cancel-sages span {
  color: #ffffff99;
}

button.proceed-dark:disabled {
  opacity: 1;
  background-color: #202939;
  color: #ffffff33;
  cursor: default;
}

button.proceed-sages:disabled {
  opacity: 1;
  background-color: #FFFFFF33;
  color: #ffffff33;
  cursor: default;
}

button.cancel-dark {
  width: calc(100% - 80px);
  background-color: #364152;
  color: white;
  border: none;
  border-radius: 99px;
  margin: 0px 0px 12px 0px;
}

button.cancel-sages {
  width: calc(100% - 80px);
  background-color: #FFFFFF66;
  color: white;
  border: none;
  border-radius: 99px;
  margin: 0px 0px 12px 0px;
}

button.cancel-dark:enabled:hover {
  background: #46556c;
}

button.cancel-sages:enabled:hover {
  background: #ffffff85;
}
