@keyframes fade-in {
  0% {
    opacity: 0;
    /*transform: translateX(100px);*/
  }
  100% {
    opacity: 1;
    /*transform: none;*/
  }
}

.root-view {
  animation: fade-in 0.5s;
  height: 483px;
}

.root-view form {
  height: 100%;
}

.on-email-view {
  height: auto !important;
}
