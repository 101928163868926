.qr-modal-bg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    transition: all 0.4s ease-in-out;
    background-color: rgba(0, 0, 0, 0.4);

    visibility: hidden;
    opacity: 0;
    z-index: 2;
}

.qr-modal-bg.visible {
    visibility: visible;
    opacity: 1;
}

.qr-modal-content {
    text-align: center;
    background-color: white;
    width: 510px;
    padding: 40px;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    backdrop-filter: blur(40px);
    height: 700px;
    box-sizing: border-box;
}

.qr-modal-content.visible {
    opacity: 1;
    visibility: visible;
    z-index: 100;
}

.qr-modal-close {
    text-align: right;
    color: #000000;
    font-size: 28px;
    font-weight: bold;
    transition: 0.3s ease;
}

.qr-modal-close:hover,
.qr-modal-close:focus {
    color: #bcbcbc;
    text-decoration: none;
    cursor: pointer;
}

.qr-code-wrapper {
    border-radius: 2px;
    background-color: white;
    height: 212px;
    width: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-text {
    font-size: 16px;
    color: #697586;
    margin-top: 20px;
    line-height: 24px;
}

.app-store {
    margin-top: 20px;
}
