/* The Modal (background) */
.issue-modal-bg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 5;
}

.issue-modal-bg.visible {
  visibility: visible;
  opacity: 1;
}

.issue-main-modal {
  display: inline;
  text-align: center;
  width: 510px;
  border-radius: 5px;
}

.issue-modal-content {
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  backdrop-filter: blur(40px);
}

.issue-modal-content.visible {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.issue-modal-content .view-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.issue-modal-content form {
  padding-bottom: 20px;
}

.issue-modal-title {
  position: relative;
  color: #121926;
  text-align: left;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}

.issue-modal-title input {
  margin: 15px 0 5px 0;
  width: calc(100% - 40px);
}

.issue-modal-title input:hover {
  border-color: #4B5565;
}

.issue-modal-title input:focus-within {
  border-color: #276EDC;
}

.issue-modal-message {
  font-family: "Inter", "Noto Sans JP",Arial,Helvetica,sans-serif;;
  font-weight: 400;
  resize: none;
  display: block;
  height: 55px;
  width: calc(100% - 40px);
  font-size: 16px;
  box-sizing: border-box;
  margin: 11px 0;
  background-color: transparent;
  color: #121926;
  max-height: 80px;
  border: none;
  border-bottom: 1px solid #CDD5DF;
  transition: 0.2s ease;
}

.issue-modal-message:focus {
  border-color: #276EDC;
}

.issue-modal-message:hover {
  border-color: #4B5565;
}

.issue-modal-message::placeholder {
  color:#697586;
}

.issue-modal-close {
  transform: translateY(-20px) translateX(20px);
  text-align: right;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  transition: 0.3s ease;
}

.issue-error {
  color: #F04438;
  width: 100%;
  margin-left: 40px;
  text-align: left;
  font-size: 12px;
  margin-top: -28px;
}
